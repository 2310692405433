import React, { useEffect } from "react";
import Layout from "@herb/layouts";
import Container from "@herb/components/Container";
import SectionHeading from "@herb/components/SectionHeading";
import { Index, connectHits, Configure } from "react-instantsearch-dom";
import InstantSearch from "@herb/components/Search/InstantSearch";
import ReactHtmlParser from "react-html-parser";
import ProductCard from "@herb/components/Cards/ProductCard";
import TrendingArticleCard from "../../components/Cards/TrendingArticleCard";
import Button from "@herb/components/Button";
import { schema, paths, deployUrl } from "@herb/utils";
import JsonLd from "@herb/components/Schema/JsonLd";
import Image from "../../components/Image";
import SEO from "@herb/components/seo";
import DisplayAd from "@herb/components/DisplayAd";
import { scriptCreate } from "@herb/utils/scripts";
import { graphql } from "gatsby";
import "./index.css";
import { Helmet } from "react-helmet";
import { fallbackImages } from "../../utils/constants";
const isBrowser = typeof window !== "undefined";
const BrandProducts = connectHits(({ hits = [], trendingProducts }) => {
  let totalProducts;
  if (trendingProducts) {
    totalProducts = [...trendingProducts, ...hits];
  } else {
    totalProducts = hits;
  }

  const seen = new Set();
  totalProducts = totalProducts.filter(el => {
    const duplicate = seen.has(el.slug);
    seen.add(el.slug);
    return !duplicate;
  });
  return (
    isBrowser &&
    totalProducts.length >= 1 && (
      <div className="brandsRelatedProducts">
        <div className="brandsProducts">
          {(totalProducts || []).map((prodcut, index) => {
            return (
              <>
                <ProductCard
                  productdetails={prodcut}
                  key={prodcut.id}
                  hideExternalLink={true}
                />
                {/* {(index + 1) % 10 === 0 && window.location.pathname !== "/" && (
                  <DisplayAd
                    visibleProps={{
                      margin: "40px 0px",
                      width: `100%`
                    }}
                    adUnit="21810472093/Homepage/"
                    banners={[
                      {
                        breakpoint: "mobile",
                        sizes: [[320, 50]]
                      },
                      {
                        breakpoint: "tablet",
                        sizes: [[320, 50]]
                      },
                      {
                        breakpoint: "desktop",
                        sizes: [[728, 90]]
                      }
                    ]}
                  />
                )} */}
              </>
            );
          })}
        </div>
      </div>
    )
  );
});

const Brand = ({ data, pageContext }) => {
  const { wordpressWpBrand: brand } = data;
  const { trendingProducts } = brand.contentBrands;
  const { featuredArticles } = brand.brandArticles;
  const { noindex, catPath } = pageContext;

  const brandSchema = {
    "@context": "http://schema.org",
    "@type": "Brand",
    name: schema.parseMeta(brand.title),
    logo: brand.logo,
    description: schema.parseMeta(brand.description)
  };

  const breadcrumbs = [
    {
      name: "Brands",
      url: `${paths.brands}/`
    },
    {
      name: brand.title,
      url: `${paths.brands}/${(brand || {}).slug}/`
    }
  ];
  const image = {};
  useEffect(() => {
    scriptCreate();
  });
  return (
    <div>
      <Layout boutiqueBackground={true} path={catPath}>
        <>
          {noindex ? (
            <Helmet>
              <meta name="robots" content="noindex"></meta>
            </Helmet>
          ) : (
            <></>
          )}
        </>
        <SEO
          title={brand.title}
          description={brand.description}
          image={brand.logo}
          url={`${paths.brands}/${(brand || {}).slug}`}
          canonical={`${deployUrl}${paths.brands}/${(brand || {}).slug}`}
          breadcrumbs={breadcrumbs}
        />
        <JsonLd data={brandSchema} />
        <Container>
          <SectionHeading heading="Brand" />
          {/* <ProductPageNav /> */}
          <div className="brandContent">
            <div className="brandLogo">
              <div className="brandPicture">
                <Image
                  class="brandImage"
                  src={brand.logo || "../assets/Ellipse 19.jpg"}
                  fallback={fallbackImages.square}
                  alt="/"
                />
              </div>
            </div>
            <div className="brandDetails">
              <h1 className="brandTitle">{brand.title}</h1>
              <p className="brandDescription">
                {ReactHtmlParser(brand.description)}
              </p>
              <Button
                type="boutiqueButton"
                role="link"
                href={(brand.contentBrands || {}).url}
                target="_blank"
                link
                rel="noopener noreferrer"
              >
                visit website
              </Button>
            </div>
          </div>
          <SectionHeading heading="shop the brand" />
          <React.Fragment>
            <InstantSearch>
              <Index indexName="Products">
                <Configure
                  aroundLatLngViaIP={false}
                  hitsPerPage={200}
                  query="*"
                  filters={`acf.brand.post_name:${(brand || {}).slug}`}
                />
                <BrandProducts trendingProducts={trendingProducts} />
              </Index>
              {featuredArticles !== null ? (
                featuredArticles.length !== 0 ? (
                  <>
                    <div className="">
                      <SectionHeading heading="READ MORE" />
                      <div className="extra-article-div">
                        {(featuredArticles || []).map((article, index) => {
                          return (
                            <TrendingArticleCard
                              article={article}
                              key={index}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </InstantSearch>
          </React.Fragment>
        </Container>
      </Layout>
    </div>
  );
};

export default Brand;

export const pageQuery = graphql`
  query($id: String!) {
    wordpressWpBrand(id: { eq: $id }) {
      title
      slug
      description
      logo
      databaseId
      brandArticles {
        featuredArticles {
          ... on WordpressWpPost {
            ...Post
          }
        }
      }
      contentBrands {
        brandPageStyle
        url
        content {
          ... on WordpressWpBrand_Contentbrands_Content_BrandFeaturedBlock {
            blurb
            cta
            link
            title
            mediaType
            mediaLocation
            image {
              sourceUrl
            }
            embedCode
            videoId
          }
          ... on WordpressWpBrand_Contentbrands_Content_BrandProductBlock {
            fieldGroupName
          }
        }
        trendingProducts {
          ... on WordpressWpProduct {
            ...Product
          }
        }
      }
    }
  }
`;
