import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link } from "gatsby";
import SideNarBar from "../Modal/SideNarBar/SideNarBar";
import AgeGate from "@herb/components/AgeGate/AgeGate";
// import { track } from "@herb/utils/analytics";
import { useScrollPercentage } from "react-scroll-percentage";
import useScrollingUp from "./NavbarHooks/useScrollingUp";
import InfiniteTextScroll from "../ProductsPage/InfiniteTextScroll";
import SearchModal from "../Modal/SearchModal/SearchModal";
import "./navbar.css";
import { color } from "styled-system";
const isBrowser = typeof window !== "undefined";
const documentCheck = typeof document !== "undefined";
const StickyNavBar = ({
  activeTab,
  entry,
  SearchModalOpen,
  searchModalOpen,
  SearchModalClose,
  toggleModal,
  modalIsOpen,
  page,
  isScrolled
}) => {
  const scrolledUp = useScrollingUp();

  useLayoutEffect(() => {
    if (isBrowser) {
      if (scrolledUp && documentCheck) {
        document.getElementsByClassName("stickyLowerSide")[0].style.marginTop =
          "-100%";
        document.getElementsByClassName("stickyLowerSide")[0].style.transition =
          "3s margin-top ease-in-out";
      } else if (documentCheck) {
        document.getElementsByClassName("stickyLowerSide")[0].style.marginTop =
          "0%";
        document.getElementsByClassName("stickyLowerSide")[0].style.transition =
          "1.5s margin-top ease-in-out";
      }
    }
  }, [scrolledUp, documentCheck]);

  const arryTabs = [
    {
      name: "NEWS",
      path: "news"
    },
    {
      name: "HOW-TOS",
      path: "how-tos",
      redirectPath: "learn"
    },
    {
      name: "Dispensaries",
      path: "dispensaries"
    },
    {
      name: "Deals",
      path: "deals"
    },
    {
      name: "PRODUCTS",
      path: "products"
    },
    {
      name: "GUIDES",
      path: "guides"
    }
  ];
  return (
    <div
      className={` ${
        (entry && !entry.isIntersecting) ||
        (page === "scavenger-hunt" && isScrolled)
          ? "stickyNavbarAlt"
          : "stickyNavbarAltHide"
      }`}
      style={page === "deals" ? { background: "#191919" } : {}}
    >
      <div
        className="navHeaderAlt"
        style={page === "deals" ? { background: "#191919" } : {}}
      >
        <div
          className="stickyUperSide"
          style={
            page === "deals"
              ? { background: "#191919", borderBottom: "1px solid #fff" }
              : {}
          }
        >
          <div className="navLogo">
            <a href="/" className="headerLogo">
              <svg
                className="stickyMobileLogo"
                xmlns="http://www.w3.org/2000/svg"
                width="164"
                height="56"
                viewBox="0 0 164 56"
                fill="none"
              >
                <path
                  d="M0 0H25.1451L25.1737 0.143454C25.0075 0.241002 24.8383 0.335681 24.6691 0.430361C21.0938 2.39854 19.1871 5.45123 19.1154 9.49376C19.0208 14.7298 19.0896 19.9687 19.0925 25.2077C19.0925 25.3196 19.1126 25.4286 19.1269 25.5921C20.8897 25.6828 22.6564 25.5156 24.3709 25.0958C26.0912 24.674 27.7542 24.126 29.4086 23.5436C32.604 22.4227 35.9578 21.8193 39.3433 21.7562C39.4809 21.7562 39.6157 21.7332 39.8221 21.7131V20.9442C39.8221 17.2345 39.8451 13.5277 39.8221 9.81796C39.7791 5.45124 37.8122 2.2264 33.8326 0.286907C33.7334 0.224249 33.6377 0.156248 33.5459 0.0832031H58.8745C58.6824 0.209442 58.5562 0.306991 58.4186 0.37011C54.7085 2.37846 52.7531 5.47419 52.7445 9.70033C52.7215 21.6816 52.7215 33.6638 52.7445 45.6469C52.7445 49.9505 54.6655 53.1237 58.4788 55.1522C58.5935 55.2153 58.7024 55.2927 58.9605 55.4391H33.5C34.0132 55.1522 34.3602 54.9857 34.7128 54.7792C37.9986 52.7708 39.7619 49.8472 39.7992 46.0084C39.8594 39.7998 39.8164 33.5882 39.8164 27.3738V26.5906C38.2624 26.7455 36.7514 26.7713 35.3006 27.0726C33.535 27.4358 31.7985 27.9287 30.1053 28.5473C26.7799 29.7813 23.2744 30.4598 19.729 30.5556C19.5455 30.5556 19.3649 30.5843 19.081 30.6044V31.4307C19.081 36.1101 19.081 40.7925 19.081 45.4748C19.081 49.8702 20.9705 53.161 24.9071 55.2296C24.9989 55.2755 25.0791 55.3386 25.3228 55.5165C24.9903 55.5366 24.844 55.5538 24.6978 55.5538H0.209303C0.137624 55.5538 0.0688121 55.5538 0 55.5538V55.3731C0.100351 55.3157 0.200702 55.2583 0.30392 55.2067C4.10579 53.2815 6.13288 50.2001 6.14721 45.9367C6.18162 33.812 6.14721 21.6902 6.14721 9.56548C6.1686 8.25208 5.9295 6.94747 5.44376 5.72713C4.95801 4.50678 4.23526 3.39494 3.31732 2.45592C2.39409 1.48331 1.3361 0.651279 0 0.286907V0Z"
                  fill={`${
                    activeTab === "videos" || page === "deals"
                      ? "#fff"
                      : "#141313"
                  }`}
                />
                <path
                  d="M124.92 55.4621C125.281 55.244 125.462 55.1293 125.648 55.026C127.713 53.8784 128.859 52.1282 129.054 49.7928C129.195 48.1316 129.324 46.4675 129.327 44.8035C129.35 33.1015 129.35 21.3986 129.327 9.69465C129.276 7.96323 129.104 6.23752 128.811 4.53032C128.555 2.78019 127.423 1.60387 125.981 0.688638C125.711 0.516493 125.436 0.347218 125.164 0.172205C125.164 0.172205 125.149 0.134907 125.121 0.0402276C125.353 0.0402276 125.565 0.00292969 125.78 0.00292969C129.052 0.00292969 132.323 0.00292969 135.592 0.00292969C136.026 0.0148655 136.452 0.120566 136.842 0.312789C139.345 1.54649 140.492 3.67247 140.595 6.35218C140.718 9.24421 140.718 12.1391 140.77 15.034C140.77 15.2836 140.77 15.5332 140.77 15.8574C141.478 15.8574 142.12 15.8574 142.762 15.8574C145.822 15.7472 148.88 16.1229 151.823 16.9706C158.532 18.979 162.838 24.1433 163.598 31.1065C164.06 35.3614 163.75 39.5072 162.136 43.5095C159.842 49.1645 155.47 52.4582 149.87 54.3661C147.561 55.1444 145.139 55.5323 142.702 55.5137C137.019 55.5137 131.337 55.5137 125.654 55.5137C125.485 55.5109 125.31 55.4879 124.92 55.4621ZM140.824 19.3634V52.0048C141.988 52.0048 143.101 52.0508 144.207 52.0048C144.993 51.9814 145.772 51.858 146.527 51.6376C149.242 50.7769 150.937 48.9349 151.479 46.1032C152.425 41.1368 152.158 36.1991 150.994 31.3102C150.203 27.9936 148.861 24.9151 146.627 22.2899C145.131 20.5139 143.29 19.3806 140.824 19.3634Z"
                  fill={`${
                    activeTab === "videos" || page === "deals"
                      ? "#fff"
                      : "#141313"
                  }`}
                />
                <path
                  d="M94.3597 33.0463H70.4533C70.9952 36.8421 71.8467 40.4284 74.6422 43.1684C78.0341 46.4908 82.0424 47.2396 86.4865 45.6874C88.978 44.8267 91.0567 43.2688 92.9548 41.467C93.1154 41.3149 93.2845 41.1801 93.4508 41.0223L93.6114 41.1084C93.4623 41.5703 93.3247 42.0408 93.1612 42.4941C91.6158 46.6285 89.2533 50.2119 85.8069 53.0035C82.9599 55.2988 79.6397 56.1394 76.0012 55.9816C68.9824 55.6775 61.7571 50.0627 59.7731 41.8802C58.3595 36.0559 58.2362 30.1858 60.4784 24.5452C63.1305 17.8689 68.0735 13.5395 75.1726 12.2082C81.6811 10.9745 87.0972 13.3071 90.9937 19.5502C92.6996 22.2815 93.5569 25.3227 93.9612 28.4931C94.1504 29.9649 94.225 31.4626 94.3597 33.0463ZM83.1491 28.3611C82.8968 26.9065 82.7104 25.5322 82.4151 24.1809C81.899 21.8053 81.1421 19.51 79.6339 17.5303C79.0032 16.7098 78.2434 16.0413 77.1768 15.8462C75.5855 15.5593 74.4558 16.3454 73.5183 17.4844C71.7378 19.6449 70.9378 22.2098 70.588 24.944C70.4361 26.0744 70.3615 27.2163 70.2497 28.3869L83.1491 28.3611Z"
                  fill={`${
                    activeTab === "videos" || page === "deals"
                      ? "#fff"
                      : "#141313"
                  }`}
                />
                <path
                  d="M117.067 29.7176C117.101 27.1728 116.989 24.7742 116.387 22.4273C116.029 21.0301 115.527 19.6845 114.529 18.6C114.153 18.1783 113.685 17.849 113.161 17.6375C112.637 17.4261 112.071 17.3382 111.507 17.3806V18.0491C111.507 27.4568 111.507 36.8645 111.533 46.275C111.575 47.6154 111.713 48.951 111.946 50.2717C112.233 52.2284 113.342 53.6543 115.008 54.6757C115.226 54.8077 115.441 54.9454 115.653 55.086C115.685 55.1089 115.699 55.1606 115.765 55.2696H95.6606C95.8146 55.1392 95.9764 55.0185 96.1452 54.9081C98.6081 53.6055 99.6374 51.4336 99.818 48.7797C99.9241 47.2333 99.9872 45.6811 99.9872 44.1319C99.9872 36.7153 99.9872 29.2987 99.947 21.8851C99.9006 20.6833 99.7481 19.488 99.4912 18.3131C99.1414 16.4654 97.9658 15.1915 96.4147 14.2247L95.6377 13.7484C95.8262 13.7005 96.0178 13.666 96.2111 13.6451C102.519 13.6451 108.827 13.6308 115.134 13.6623C116.943 13.6752 118.735 14.0174 120.421 14.6722C123.679 15.9174 125.428 18.402 126.245 21.6756C126.448 22.479 126.87 23.4401 126.6 24.0856C126.331 24.7312 125.339 25.041 124.633 25.4485C122.34 26.7625 120.046 28.0536 117.752 29.3533C117.554 29.4652 117.356 29.5656 117.067 29.7176Z"
                  fill={`${
                    activeTab === "videos" || page === "deals"
                      ? "#fff"
                      : "#141313"
                  }`}
                />
              </svg>
            </a>
          </div>
          <div className="navBarAltContent">
            <div className="herbAdvertisement">
              <div className="advertiseMobile">
                <Link
                  href="https://www.herb.agency/get-in-touch"
                  className={`advertiseHerbBtn ${
                    page === "deals"
                      ? "advertiseHerbBtnDeals"
                      : page === "scavenger-hunt"
                      ? "advertiseHerbBtnBgScavenger"
                      : ""
                  }`}
                >
                  advertise with herb
                </Link>
              </div>
            </div>
            {/* <div className="getFMDMedCard medCardMobile">
              <a
                href="https://www.nuggmd.com/herb24?utm_source=herb&utm_medium=site&utm_campaign=herb24&utm_content=getacardbutton"
                className="getMedCardBtnMobile"
                target="_blank"
                rel="noreferrer"
              >
                Get Med Card
              </a>
            </div> */}
            <div className="navSearch">
              <form className="searchForm" action="/action_page.php">
                <div className="searchSvg searchLogo" type="submit">
                  <svg
                    onClick={SearchModalOpen}
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.48655 4.62034C8.98034 1.12655 14.6449 1.12655 18.1387 4.62034C21.6325 8.11413 21.6325 13.7787 18.1387 17.2725C14.6449 20.7663 8.98034 20.7663 5.48655 17.2725C1.99276 13.7787 1.99276 8.11413 5.48655 4.62034ZM19.5529 3.20613C15.2781 -1.06871 8.34717 -1.06871 4.07234 3.20613C-0.202498 7.48096 -0.202498 14.4118 4.07234 18.6867C8.04991 22.6642 14.327 22.9408 18.6243 19.5165L23.2415 24.1336L24.9998 22.3753L20.3826 17.7582C23.8071 13.4608 23.5305 7.18371 19.5529 3.20613Z"
                      fill={`${
                        activeTab === "videos" || page === "deals"
                          ? "#fff"
                          : "#141313"
                      }`}
                    />
                  </svg>
                </div>
                <SearchModal
                  toggleSearchModal={SearchModalClose}
                  searchModalOpen={searchModalOpen}
                />
              </form>
            </div>

            <div className="navButtons">
              <div
                className={`mobileSearchSvg ${page === "deals" ? "ml-10" : ""}`}
              >
                <svg
                  onClick={SearchModalOpen}
                  className="svgMobileSearch"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="12"
                    fill={`${page === "deals" ? "#fff" : "#000"}`}
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.29738 8.29738C10.0346 6.56016 12.8512 6.56016 14.5884 8.29738C16.3256 10.0346 16.3256 12.8512 14.5884 14.5884C12.8512 16.3256 10.0346 16.3256 8.29738 14.5884C6.56016 12.8512 6.56016 10.0346 8.29738 8.29738ZM15.2916 7.59419C13.166 5.4686 9.71977 5.4686 7.59419 7.59419C5.4686 9.71977 5.4686 13.166 7.59419 15.2916C9.57196 17.2694 12.6931 17.4069 14.8299 15.7042L17.1257 18L18 17.1257L15.7042 14.8299C17.4069 12.6931 17.2694 9.57197 15.2916 7.59419Z"
                    fill={`${page === "deals" ? "#000" : "#fff"}`}
                  />
                </svg>
              </div>
              <div className="navBtnContent">
                <div className="subscribeNavButoon">
                  <Link
                    to="/newsletters"
                    className={`${
                      activeTab === "videos" || page === "deals"
                        ? "navBtnsvgVideo"
                        : "navBtnsvg"
                    }`}
                  >
                    <span className="navBtnSvgDiv">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="20"
                        viewBox="0 0 24 20"
                        fill="none"
                      >
                        <path
                          className="subscribeSVG"
                          d="M21.6 0.566895H2.4C1.08 0.566895 0.012 1.63564 0.012 2.94189L0 17.1919C0 18.4981 1.08 19.5669 2.4 19.5669H21.6C22.92 19.5669 24 18.4981 24 17.1919V2.94189C24 1.63564 22.92 0.566895 21.6 0.566895ZM21.6 5.31689L12 11.2544L2.4 5.31689V2.94189L12 8.87939L21.6 2.94189V5.31689Z"
                          fill={`${
                            activeTab === "videos" || page === "deals"
                              ? "#000"
                              : "#fff"
                          }`}
                        />
                      </svg>
                    </span>
                    Subscribe
                  </Link>
                </div>
                {/* <div className="getFMDMedCard">
                  <a
                    href="https://www.nuggmd.com/herb24?utm_source=herb&utm_medium=site&utm_campaign=herb24&utm_content=getacardbutton"
                    className="getMedCardBtn"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Get Med Card
                  </a>
                </div> */}
                <div className="advertiseNavLink">
                  <Link
                    href="https://www.herb.agency/get-in-touch"
                    className={
                      page === "deals" ? "advertiseHerbDeals" : "advertiseHerb"
                    }
                  >
                    advertise with herb
                  </Link>
                </div>
              </div>
              <nav className="hamNav">
                <div
                  onClick={toggleModal}
                  className={`hamburgerlines ${
                    page === "deals" ? "ml-10" : ""
                  }`}
                >
                  <span
                    className={`${
                      activeTab === "videos" || page === "deals"
                        ? "linesVideo"
                        : "lines"
                    }`}
                  ></span>
                  <span
                    className={`${
                      activeTab === "videos" || page === "deals"
                        ? "linesVideo"
                        : "lines"
                    }`}
                  ></span>
                  <span
                    className={`${
                      activeTab === "videos" || page === "deals"
                        ? "linesVideo"
                        : "lines"
                    }`}
                  ></span>
                </div>

                <SideNarBar
                  toggleModal={toggleModal}
                  modalIsOpen={modalIsOpen}
                />
              </nav>
            </div>
          </div>
        </div>
        <div
          className="stickyLowerSide"
          style={page === "deals" ? { background: "#191919" } : {}}
        >
          <div className="links">
            {arryTabs.map(tab => (
              <Link
                key={tab.name}
                className={`  
              ${
                activeTab === "videos" || page === "deals"
                  ? "linkColorVideo"
                  : "linkColor"
              }
              ${
                activeTab === tab.path || activeTab === tab.redirectPath
                  ? "linkColorActive"
                  : ""
              }

              `}
                to={`/${tab.path}`}
              >
                {tab.name}
              </Link>
            ))}
          </div>
        </div>
      </div>
      {page === "products" ? <InfiniteTextScroll /> : <></>}
    </div>
  );
};
function Navbar({ page }) {
  // TABS
  const arryTabs = [
    {
      name: "NEWS",
      path: "news"
    },
    {
      name: "HOW-TOS",
      path: "how-tos",
      redirectPath: "learn"
    },
    {
      name: "Dispensaries",
      path: "dispensaries"
    },
    {
      name: "Deals",
      path: "deals"
    },
    {
      name: "PRODUCTS",
      path: "products"
    },
    {
      name: "GUIDES",
      path: "guides"
    }
  ];
  const [activeTab, setActiveTab] = React.useState("/");
  const [isScrolled, setIsScrolled] = useState(false);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [ref, percentageLocal, entry] = useScrollPercentage({
    /* Optional options */
    threshold: 1
  });

  const toggleModal = () => {
    // if (modalIsOpen) {
    //   track("Click Button", {
    //     name: "Open Desktop Navigation"
    //   });
    // }
    setIsOpen(!modalIsOpen);
  };

  const [searchModalOpen, smIsOpen] = React.useState(false);

  const SearchModalOpen = () => {
    // track("Open Search", {
    //   name: "Main Navigation"
    // });
    smIsOpen(true);
  };

  const SearchModalClose = e => {
    e.stopPropagation();
    // track("Click Button", {
    //   name: "Cancel Search"
    // });
    smIsOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 100;
      setIsScrolled(window.scrollY > scrollThreshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const page = window.location.pathname.split("/");
    const classassigne = page[1] ? page[1] : "";
    setActiveTab(classassigne);
  }, []);

  return (
    <div
      ref={ref}
      className={`mainHeader ${page === "scavenger-hunt" && "absolute"}`}
    >
      <StickyNavBar
        activeTab={activeTab}
        entry={entry}
        isScrolled={isScrolled}
        SearchModalOpen={SearchModalOpen}
        SearchModalClose={SearchModalClose}
        toggleModal={toggleModal}
        modalIsOpen={modalIsOpen}
        page={page}
      />

      <div
        className={` ${
          (entry && !entry.isIntersecting) ||
          (page === "scavenger-hunt" && isScrolled)
            ? "navHeaderHide"
            : "navHeader"
        }`}
        style={
          page === "dispensaryListing" || page === "dispensary"
            ? { backgroundColor: "white" }
            : {}
        }
      >
        <div
          className={`upperSide ${page === "scavenger-hunt" &&
            "borderBottomScavenger"}`}
        >
          <div className="navLogo">
            <a href="/" className="headerLogo">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="164"
                height="56"
                viewBox="0 0 164 56"
                fill="none"
              >
                <path
                  d="M0 0H25.1451L25.1737 0.143454C25.0075 0.241002 24.8383 0.335681 24.6691 0.430361C21.0938 2.39854 19.1871 5.45123 19.1154 9.49376C19.0208 14.7298 19.0896 19.9687 19.0925 25.2077C19.0925 25.3196 19.1126 25.4286 19.1269 25.5921C20.8897 25.6828 22.6564 25.5156 24.3709 25.0958C26.0912 24.674 27.7542 24.126 29.4086 23.5436C32.604 22.4227 35.9578 21.8193 39.3433 21.7562C39.4809 21.7562 39.6157 21.7332 39.8221 21.7131V20.9442C39.8221 17.2345 39.8451 13.5277 39.8221 9.81796C39.7791 5.45124 37.8122 2.2264 33.8326 0.286907C33.7334 0.224249 33.6377 0.156248 33.5459 0.0832031H58.8745C58.6824 0.209442 58.5562 0.306991 58.4186 0.37011C54.7085 2.37846 52.7531 5.47419 52.7445 9.70033C52.7215 21.6816 52.7215 33.6638 52.7445 45.6469C52.7445 49.9505 54.6655 53.1237 58.4788 55.1522C58.5935 55.2153 58.7024 55.2927 58.9605 55.4391H33.5C34.0132 55.1522 34.3602 54.9857 34.7128 54.7792C37.9986 52.7708 39.7619 49.8472 39.7992 46.0084C39.8594 39.7998 39.8164 33.5882 39.8164 27.3738V26.5906C38.2624 26.7455 36.7514 26.7713 35.3006 27.0726C33.535 27.4358 31.7985 27.9287 30.1053 28.5473C26.7799 29.7813 23.2744 30.4598 19.729 30.5556C19.5455 30.5556 19.3649 30.5843 19.081 30.6044V31.4307C19.081 36.1101 19.081 40.7925 19.081 45.4748C19.081 49.8702 20.9705 53.161 24.9071 55.2296C24.9989 55.2755 25.0791 55.3386 25.3228 55.5165C24.9903 55.5366 24.844 55.5538 24.6978 55.5538H0.209303C0.137624 55.5538 0.0688121 55.5538 0 55.5538V55.3731C0.100351 55.3157 0.200702 55.2583 0.30392 55.2067C4.10579 53.2815 6.13288 50.2001 6.14721 45.9367C6.18162 33.812 6.14721 21.6902 6.14721 9.56548C6.1686 8.25208 5.9295 6.94747 5.44376 5.72713C4.95801 4.50678 4.23526 3.39494 3.31732 2.45592C2.39409 1.48331 1.3361 0.651279 0 0.286907V0Z"
                  fill={`${
                    activeTab === "videos" ||
                    page === "deals" ||
                    page === "scavenger-hunt"
                      ? "#fff"
                      : "#141313"
                  }`}
                />
                <path
                  d="M124.92 55.4621C125.281 55.244 125.462 55.1293 125.648 55.026C127.713 53.8784 128.859 52.1282 129.054 49.7928C129.195 48.1316 129.324 46.4675 129.327 44.8035C129.35 33.1015 129.35 21.3986 129.327 9.69465C129.276 7.96323 129.104 6.23752 128.811 4.53032C128.555 2.78019 127.423 1.60387 125.981 0.688638C125.711 0.516493 125.436 0.347218 125.164 0.172205C125.164 0.172205 125.149 0.134907 125.121 0.0402276C125.353 0.0402276 125.565 0.00292969 125.78 0.00292969C129.052 0.00292969 132.323 0.00292969 135.592 0.00292969C136.026 0.0148655 136.452 0.120566 136.842 0.312789C139.345 1.54649 140.492 3.67247 140.595 6.35218C140.718 9.24421 140.718 12.1391 140.77 15.034C140.77 15.2836 140.77 15.5332 140.77 15.8574C141.478 15.8574 142.12 15.8574 142.762 15.8574C145.822 15.7472 148.88 16.1229 151.823 16.9706C158.532 18.979 162.838 24.1433 163.598 31.1065C164.06 35.3614 163.75 39.5072 162.136 43.5095C159.842 49.1645 155.47 52.4582 149.87 54.3661C147.561 55.1444 145.139 55.5323 142.702 55.5137C137.019 55.5137 131.337 55.5137 125.654 55.5137C125.485 55.5109 125.31 55.4879 124.92 55.4621ZM140.824 19.3634V52.0048C141.988 52.0048 143.101 52.0508 144.207 52.0048C144.993 51.9814 145.772 51.858 146.527 51.6376C149.242 50.7769 150.937 48.9349 151.479 46.1032C152.425 41.1368 152.158 36.1991 150.994 31.3102C150.203 27.9936 148.861 24.9151 146.627 22.2899C145.131 20.5139 143.29 19.3806 140.824 19.3634Z"
                  fill={`${
                    activeTab === "videos" ||
                    page === "deals" ||
                    page === "scavenger-hunt"
                      ? "#fff"
                      : "#141313"
                  }`}
                />
                <path
                  d="M94.3597 33.0463H70.4533C70.9952 36.8421 71.8467 40.4284 74.6422 43.1684C78.0341 46.4908 82.0424 47.2396 86.4865 45.6874C88.978 44.8267 91.0567 43.2688 92.9548 41.467C93.1154 41.3149 93.2845 41.1801 93.4508 41.0223L93.6114 41.1084C93.4623 41.5703 93.3247 42.0408 93.1612 42.4941C91.6158 46.6285 89.2533 50.2119 85.8069 53.0035C82.9599 55.2988 79.6397 56.1394 76.0012 55.9816C68.9824 55.6775 61.7571 50.0627 59.7731 41.8802C58.3595 36.0559 58.2362 30.1858 60.4784 24.5452C63.1305 17.8689 68.0735 13.5395 75.1726 12.2082C81.6811 10.9745 87.0972 13.3071 90.9937 19.5502C92.6996 22.2815 93.5569 25.3227 93.9612 28.4931C94.1504 29.9649 94.225 31.4626 94.3597 33.0463ZM83.1491 28.3611C82.8968 26.9065 82.7104 25.5322 82.4151 24.1809C81.899 21.8053 81.1421 19.51 79.6339 17.5303C79.0032 16.7098 78.2434 16.0413 77.1768 15.8462C75.5855 15.5593 74.4558 16.3454 73.5183 17.4844C71.7378 19.6449 70.9378 22.2098 70.588 24.944C70.4361 26.0744 70.3615 27.2163 70.2497 28.3869L83.1491 28.3611Z"
                  fill={`${
                    activeTab === "videos" ||
                    page === "deals" ||
                    page === "scavenger-hunt"
                      ? "#fff"
                      : "#141313"
                  }`}
                />
                <path
                  d="M117.067 29.7176C117.101 27.1728 116.989 24.7742 116.387 22.4273C116.029 21.0301 115.527 19.6845 114.529 18.6C114.153 18.1783 113.685 17.849 113.161 17.6375C112.637 17.4261 112.071 17.3382 111.507 17.3806V18.0491C111.507 27.4568 111.507 36.8645 111.533 46.275C111.575 47.6154 111.713 48.951 111.946 50.2717C112.233 52.2284 113.342 53.6543 115.008 54.6757C115.226 54.8077 115.441 54.9454 115.653 55.086C115.685 55.1089 115.699 55.1606 115.765 55.2696H95.6606C95.8146 55.1392 95.9764 55.0185 96.1452 54.9081C98.6081 53.6055 99.6374 51.4336 99.818 48.7797C99.9241 47.2333 99.9872 45.6811 99.9872 44.1319C99.9872 36.7153 99.9872 29.2987 99.947 21.8851C99.9006 20.6833 99.7481 19.488 99.4912 18.3131C99.1414 16.4654 97.9658 15.1915 96.4147 14.2247L95.6377 13.7484C95.8262 13.7005 96.0178 13.666 96.2111 13.6451C102.519 13.6451 108.827 13.6308 115.134 13.6623C116.943 13.6752 118.735 14.0174 120.421 14.6722C123.679 15.9174 125.428 18.402 126.245 21.6756C126.448 22.479 126.87 23.4401 126.6 24.0856C126.331 24.7312 125.339 25.041 124.633 25.4485C122.34 26.7625 120.046 28.0536 117.752 29.3533C117.554 29.4652 117.356 29.5656 117.067 29.7176Z"
                  fill={`${
                    activeTab === "videos" ||
                    page === "deals" ||
                    page === "scavenger-hunt"
                      ? "#fff"
                      : "#141313"
                  }`}
                />
              </svg>
            </a>
          </div>
          <div className="navBarContent">
            <div className="herbAdvertisement">
              <div className="advertiseMobile">
                <Link
                  href="https://www.herb.agency/get-in-touch"
                  className={`advertiseHerbBtn ${
                    page === "deals"
                      ? "advertiseHerbBtnDeals"
                      : page === "scavenger-hunt"
                      ? "advertiseHerbBtnBgScavenger"
                      : ""
                  }`}
                >
                  advertise with herb
                </Link>
              </div>
            </div>
            {/* <div className="getFMDMedCard medCardMobile">
              <a
                href="https://www.nuggmd.com/herb24?utm_source=herb&utm_medium=site&utm_campaign=herb24&utm_content=getacardbutton"
                className="getMedCardBtnMobile"
                target="_blank"
                rel="noreferrer"
              >
                Get Med Card
              </a>
            </div> */}
            <div className="navSearch">
              <form className="searchForm" action="/action_page.php">
                <input
                  onClick={SearchModalOpen}
                  className={`${
                    activeTab === "videos" ||
                    page === "deals" ||
                    page === "scavenger-hunt"
                      ? "searchInputVideo"
                      : "searchInput"
                  }`}
                  readOnly
                  type="text"
                  placeholder="Search for something"
                  name="search"
                />
                <div className="searchSvg searchLogo" type="submit">
                  <svg
                    onClick={SearchModalOpen}
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.48655 4.62034C8.98034 1.12655 14.6449 1.12655 18.1387 4.62034C21.6325 8.11413 21.6325 13.7787 18.1387 17.2725C14.6449 20.7663 8.98034 20.7663 5.48655 17.2725C1.99276 13.7787 1.99276 8.11413 5.48655 4.62034ZM19.5529 3.20613C15.2781 -1.06871 8.34717 -1.06871 4.07234 3.20613C-0.202498 7.48096 -0.202498 14.4118 4.07234 18.6867C8.04991 22.6642 14.327 22.9408 18.6243 19.5165L23.2415 24.1336L24.9998 22.3753L20.3826 17.7582C23.8071 13.4608 23.5305 7.18371 19.5529 3.20613Z"
                      fill={`${
                        activeTab === "videos" ||
                        page === "deals" ||
                        page === "scavenger-hunt"
                          ? "#fff"
                          : "#141313"
                      }`}
                    />
                  </svg>
                </div>
                <SearchModal
                  toggleSearchModal={SearchModalClose}
                  searchModalOpen={searchModalOpen}
                />
              </form>
            </div>

            <div className="navButtons">
              <div
                className={`mobileSearchSvg ${page === "deals" ? "ml-10" : ""}`}
              >
                <svg
                  onClick={SearchModalOpen}
                  className="svgMobileSearch"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="12"
                    fill={`${page === "deals" ? "#fff" : "#000"}`}
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.29738 8.29738C10.0346 6.56016 12.8512 6.56016 14.5884 8.29738C16.3256 10.0346 16.3256 12.8512 14.5884 14.5884C12.8512 16.3256 10.0346 16.3256 8.29738 14.5884C6.56016 12.8512 6.56016 10.0346 8.29738 8.29738ZM15.2916 7.59419C13.166 5.4686 9.71977 5.4686 7.59419 7.59419C5.4686 9.71977 5.4686 13.166 7.59419 15.2916C9.57196 17.2694 12.6931 17.4069 14.8299 15.7042L17.1257 18L18 17.1257L15.7042 14.8299C17.4069 12.6931 17.2694 9.57197 15.2916 7.59419Z"
                    fill={`${page === "deals" ? "#000" : "#fff"}`}
                  />
                </svg>
              </div>
              <div className="navBtnContent">
                <div className="subscribeNavButoon">
                  <Link
                    to="/newsletters"
                    className={`${
                      activeTab === "videos" || page === "deals"
                        ? "navBtnsvgVideo"
                        : page === "scavenger-hunt"
                        ? "navBtnsvgScavengerHunt"
                        : "navBtnsvg"
                    }`}
                  >
                    <span className="navBtnSvgDiv">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="20"
                        viewBox="0 0 24 20"
                        fill="none"
                      >
                        <path
                          className="subscribeSVG"
                          d="M21.6 0.566895H2.4C1.08 0.566895 0.012 1.63564 0.012 2.94189L0 17.1919C0 18.4981 1.08 19.5669 2.4 19.5669H21.6C22.92 19.5669 24 18.4981 24 17.1919V2.94189C24 1.63564 22.92 0.566895 21.6 0.566895ZM21.6 5.31689L12 11.2544L2.4 5.31689V2.94189L12 8.87939L21.6 2.94189V5.31689Z"
                          fill={`${
                            activeTab === "videos" ||
                            page === "deals" ||
                            page === "scavenger-hunt"
                              ? "#000"
                              : "#fff"
                          }`}
                        />
                      </svg>
                    </span>
                    Subscribe
                  </Link>
                </div>
                {/* <div className="getFMDMedCard">
                  <a
                    href="https://www.nuggmd.com/herb24?utm_source=herb&utm_medium=site&utm_campaign=herb24&utm_content=getacardbutton"
                    className="getMedCardBtn"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Get Med Card
                  </a>
                </div> */}
                <div className="advertiseNavLink">
                  <Link
                    href="https://www.herb.agency/get-in-touch"
                    className={
                      page === "deals"
                        ? "advertiseHerbDeals"
                        : page === "scavenger-hunt"
                        ? "advertiseHerbScavengerHunt"
                        : "advertiseHerb"
                    }
                  >
                    advertise with herb
                  </Link>
                </div>
              </div>
              <nav className="hamNav">
                <div
                  onClick={toggleModal}
                  className={`hamburgerlines ${
                    page === "deals" ? "ml-10" : ""
                  }`}
                >
                  <span
                    className={`${
                      activeTab === "videos" || page === "deals"
                        ? "linesVideo"
                        : page === "scavenger-hunt"
                        ? "linesScavengerHunt"
                        : "lines"
                    }`}
                  ></span>
                  <span
                    className={`${
                      activeTab === "videos" || page === "deals"
                        ? "linesVideo"
                        : page === "scavenger-hunt"
                        ? "linesScavengerHunt"
                        : "lines"
                    }`}
                  ></span>
                  <span
                    className={`${
                      activeTab === "videos" || page === "deals"
                        ? "linesVideo"
                        : page === "scavenger-hunt"
                        ? "linesScavengerHunt"
                        : "lines"
                    }`}
                  ></span>
                </div>
                {/* <animated.div style={props}> */}
                <SideNarBar
                  toggleModal={toggleModal}
                  modalIsOpen={modalIsOpen}
                />
                {/* </animated.div> */}
              </nav>
            </div>
          </div>
        </div>

        <div className="lowerSide">
          <div className="links">
            {arryTabs.map(tab => (
              <Link
                key={tab.name}
                className={`  
              ${
                activeTab === "videos" ||
                page === "deals" ||
                page === "scavenger-hunt"
                  ? "linkColorVideo"
                  : "linkColor"
              }
              ${
                activeTab === tab.path || activeTab === tab.redirectPath
                  ? "linkColorActive"
                  : ""
              }

              `}
                to={`/${tab.path}`}
              >
                {tab.name}
              </Link>
            ))}
          </div>
          {page === "products" ? <InfiniteTextScroll /> : <></>}
        </div>

        <AgeGate />
      </div>
    </div>
  );
}

export default Navbar;
