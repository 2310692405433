import React, { useEffect, useRef, useState } from "react";
import "./index.css";

const AlreadySubmittedPopup = ({ handleEmailPopup }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleEmailPopup();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleEmailPopup]);

  return (
    <div className="overlay">
      <div className="already-submit-popup" ref={popupRef}>
        <div className="logoMain">
          <img
            src="../images/herb-logo.svg"
            alt="Herb Logo"
            className="herbPopupLogo"
          />
          <button className="close-button" onClick={handleEmailPopup}>
            &times;
          </button>
        </div>
        <div className="popUpTextMain">
          <div className="popUpTextContainer">
            <p className="alreadySubmitHeading">
              Entry already <br />
              submitted
            </p>
            <p className="alreadySubmitDes">
              Oops! Looks like{" "}
              <span>you already have this entry in your collection. </span>
              Check back tomorrow for more!
            </p>
          </div>
        </div>
        <div className="popUpFooterMain">
          <p className="popUpFooterText">
            Keep an eye on @Herb (IG) and @Herbworthy (X) for clues on where to
            find more stickers.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AlreadySubmittedPopup;
