import React from "react";
import Button from "@herb/components/Button";
import ProductCard from "../Cards/ProductCard";
import InstantSearch from "@herb/components/Search/InstantSearch";
import {
  Index,
  connectInfiniteHits,
  connectStateResults,
  Configure
} from "react-instantsearch-dom";
import "./index.css";
import ClipLoader from "react-spinners/ClipLoader";
// import DisplayAd from "@herb/components/DisplayAd";
import { Flex, Box } from "@herb/ui";
const isBrowser = typeof window !== "undefined";

const getScreenWidth = () => {
  if (typeof window !== "undefined") {
    return window.screen.width;
  }
};

const CustomHits = connectInfiniteHits(
  ({ hits, hasMore, refine, showAd, trendingProducts = [] }) => {
    const totalProducts = [...trendingProducts, ...hits];
    // Remove Duplicate products
    const uniqueAddresses = Array.from(
      new Set(totalProducts.map(a => a.id))
    ).map(id => {
      return totalProducts.find(a => a.id === id);
    });
    return (
      <React.Fragment>
        {(uniqueAddresses || []).map((product, index) => (
          <React.Fragment key={product.id}>
            <ProductCard
              productdetails={product}
              key={product.id}
              hideExternalLink={true}
            />

            {/* {(index + 1) % 10 === 0 &&
              (typeof window !== "undefined"
                ? window.location.pathname !== "/"
                : "") && (
                <DisplayAd
                  visibleProps={{
                    margin: "40px 0px",
                    width: `100%`
                  }}
                  adUnit="21810472093/Homepage/"
                  banners={[
                    {
                      breakpoint: "mobile",
                      sizes: [[320, 50]]
                    },
                    {
                      breakpoint: "tablet",
                      sizes: [[320, 50]]
                    },
                    {
                      breakpoint: "desktop",
                      sizes: [[728, 90]]
                    }
                  ]}
                />
              )} */}
          </React.Fragment>
        ))}
        {isBrowser && hasMore && window.location.pathname.includes("products") && (
          <div className="fullShopButton">
            <Button type="boutiqueButton" onClick={refine}>
              Show More
            </Button>
          </div>
        )}
      </React.Fragment>
    );
  }
);

const Loading = connectStateResults(
  ({ searching }) =>
    searching && (
      <Flex width={1} flex="1" alignItems="center" justifyContent="center">
        <ClipLoader size={24} color="#f76f6f" loading={true} />
      </Flex>
    )
);

const ProductList = ({ products, showAd = false, trendingProducts }) => {
  const width = getScreenWidth();
  const hitPerPage = width <= 425 ? 8 : 20;
  return (
    <div className="mainProducts">
      {trendingProducts ? (
        <InstantSearch>
          <Index indexName="Products">
            <Configure
              aroundLatLngViaIP={false}
              hitsPerPage={hitPerPage}
              query="*"
            />
            <div className="mProducts">
              <CustomHits showAd={showAd} trendingProducts={trendingProducts} />
              <Box width={1} mt={3}>
                <Loading />
              </Box>
            </div>
          </Index>
        </InstantSearch>
      ) : isBrowser &&
        window.location.pathname !== "/" &&
        !window.location.pathname.includes("products") ? (
        <InstantSearch>
          <Index indexName="Products">
            <Configure
              aroundLatLngViaIP={false}
              hitsPerPage={hitPerPage}
              query="*"
            />
            <div className="mProducts">
              <CustomHits showAd={showAd} />
              <Box width={1} mt={3}>
                <Loading />
              </Box>
            </div>
          </Index>
        </InstantSearch>
      ) : (
        <div className="mProducts">
          {(products || []).map(product => (
            <ProductCard
              productdetails={product}
              key={product.id}
              hideExternalLink={true}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductList;
