import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Image from "@herb/components/Image";
import { navigate } from "gatsby";
import "./index.css";
import AlreadySubmittedPopup from "../AlreadySubmitPopup";

const ScavengerHuntPopUp = ({ handleEmailPopup, articleSlug }) => {
  const popupRef = useRef(null);

  const today = new Date(); // Complete date
  const firstEntryKey = "scavenger_first_entry";
  const lastEntryKey = "scavenger_last_entry";

  const [entryAlreadySubmitted, setEntryAlreadySubmitted] = useState(false);

  const firstEntryUsed = localStorage.getItem(firstEntryKey);
  const lastEntryDate = localStorage.getItem(lastEntryKey);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Required")
  });
  // Convert the date to PST
  function convertToPST(dateString) {
    const date = new Date(dateString);
    const options = {
      timeZone: "America/Los_Angeles",
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    };
    const formatter = new Intl.DateTimeFormat("en-US", options);

    const [
      { value: month },
      ,
      { value: day },
      ,
      { value: year }
    ] = formatter.formatToParts(date);
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleEmailPopup();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleEmailPopup]);

  useEffect(() => {
    if (
      (articleSlug === "cannabis-420-scavenger-hunt" &&
        firstEntryUsed === "true") ||
      (articleSlug !== "cannabis-420-scavenger-hunt" &&
        convertToPST(lastEntryDate) === convertToPST(today))
    ) {
      setEntryAlreadySubmitted(true);
    }
  });

  return (
    <>
      {entryAlreadySubmitted ? (
        <AlreadySubmittedPopup handleEmailPopup={handleEmailPopup} />
      ) : (
        <div className="scavengerPopup">
          <div className="overlay">
            <div className="popup" ref={popupRef}>
              <button className="close-btn" onClick={handleEmailPopup}>
                &times;
              </button>
              <div className="popup-content">
                <div className="text-section">
                  <div className="logo">
                    <img src="../images/herb-logo.svg" alt="Herb Logo" />
                    <button
                      className="close-btn-mobile"
                      onClick={handleEmailPopup}
                    >
                      &times;
                    </button>
                  </div>
                  <h2 className="mt-40">
                    <span className="highlight">STICKER FOUND!</span>
                  </h2>
                  <p className="sub-title">
                    Now officially added to your collection!
                  </p>
                  <p className="description">
                    You just scored another entry into Herb's 4/20 Giveaway.
                    Keep hunting daily to maximize your chances of winning on
                    April 20.
                  </p>
                  <Formik
                    initialValues={{ email: "" }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, actions) => {
                      actions.setSubmitting(true);
                      const options = {
                        method: "POST",
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                          email: values.email,
                          firstName: values.firstName,
                          lastName: values.lastName,
                          listId: "RCaYQD",
                          lastEntryDate: today,
                          articleSlug: articleSlug
                        })
                      };
                      let url =
                        "https://us-central1-herb-platform-v3.cloudfunctions.net/handle420GiveAwayProfile";

                      const response = await fetch(url, options);
                      const res = await response.json();

                      if (!res.success && res?.alreadyEntered) {
                        localStorage.setItem(
                          firstEntryKey,
                          res?.firstEntryUsed
                        );
                        localStorage.setItem(lastEntryKey, res?.lastEntryDate);
                        setEntryAlreadySubmitted(true);
                      }

                      if (res?.success) {
                        if (
                          articleSlug === "cannabis-420-scavenger-hunt" &&
                          (firstEntryUsed === "false" || !firstEntryUsed)
                        ) {
                          localStorage.setItem(firstEntryKey, "true");
                        } else if (
                          articleSlug !== "cannabis-420-scavenger-hunt" &&
                          convertToPST(lastEntryDate) !== convertToPST(today)
                        ) {
                          localStorage.setItem(lastEntryKey, today);
                        }
                        navigate("/thank-you");
                      } else {
                        actions.setSubmitting(false);
                        actions.setStatus({
                          message: res?.message
                        });
                      }
                    }}
                  >
                    {({ isSubmitting, status }) => (
                      <Form>
                        {(status || {}).message ? (
                          <p>{status?.message}</p>
                        ) : (
                          <>
                            <div className="input-fields">
                              <div>
                                <label>Enter your first name</label>
                                <Field
                                  type="text"
                                  name="firstName"
                                  placeholder="First name"
                                />
                              </div>
                              <div>
                                <label>Enter your last name</label>
                                <Field
                                  type="text"
                                  name="lastName"
                                  placeholder="Last name"
                                />
                              </div>
                            </div>
                            <label>
                              Where should we contact you if you win?
                            </label>
                            <Field
                              type="email"
                              name="email"
                              placeholder="Email address"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="error-message"
                            />
                            <button
                              type="submit"
                              className={`submit-btn ${
                                isSubmitting ? "disabled" : ""
                              }`}
                              disabled={isSubmitting}
                            >
                              {isSubmitting ? "PROCESSING" : "SUBMIT NOW"}
                            </button>
                          </>
                        )}
                      </Form>
                    )}
                  </Formik>
                  <p className="note">
                    Keep an eye on @Herb (IG) and @Herbworthy (X) for clues on
                    where to find more stickers. Don't forget to join our live
                    Instagram broadcast on 4/20 at 4:20 PM PST, where we'll
                    announce ALL winners.
                  </p>
                </div>
                <div className="image-section">
                  <Image
                    src="https://herb-platform-images.imgix.net/wp-content/uploads/2025/03/28065428/Sticker1-1.png"
                    className="sticker1"
                  />
                  <Image
                    src="https://herb-platform-images.imgix.net/wp-content/uploads/2025/03/28065441/Sticker2-1.png"
                    className="sticker2"
                  />
                  <Image
                    src="https://herb-platform-images.imgix.net/wp-content/uploads/2025/03/17072240/Sticker3-1.png"
                    className="sticker3"
                  />
                  {/* <Image
                    src="https://herb-platform-images.imgix.net/wp-content/uploads/2025/03/17072241/Sticker4-1.png"
                    className="sticker4"
                  /> */}
                  <Image
                    src="https://herb-platform-images.imgix.net/wp-content/uploads/2025/03/17072242/Sticker5-1.png"
                    className="sticker5"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ScavengerHuntPopUp;
