import React from "react";
import Image from "@herb/components/Image";
import "./index.css";
import { fallbackImages } from "../../../utils/constants";

const DealProductHit = (hit, name) => {
  const {
    dealPrice,
    dealsPageUrl,
    product,
    productPrice,
    badgeText,
    badgeTextColor,
    discountCode
  } = hit.hit ? hit.hit.contentDeals.content[0] : hit.contentDeals.content[0];
  const title = product?.title;
  const images = product?.images;
  const category = product?.category;
  const brand = product?.brand_name;
  return product ? (
    <div className="dealProductCardAlgolia">
      <div className="dealProductCardImageDiv">
        <Image
          className="dealProductCardImageAlgolia"
          src={images[0]}
          width={500}
        />
      </div>
      <div className="dealProductCardContentAlgolia">
        <p
          className={
            badgeText ? "dealProductCardPromoTextAlgolia" : "badgeTextEmpty"
          }
        >
          {badgeText ? badgeText : ""}
        </p>
        <p className="dealProductCardCategoryAlgolia">{category}</p>
        <p className="dealProductCardBrandAlgolia">{brand ? brand : ""}</p>
        <h5 className="dealProductCardTitleAlgolia">{title}</h5>
        <div
          className="dealProductCardPricesAlgolia"
          style={{
            visibility: dealPrice || productPrice ? "visible" : "hidden"
          }}
        >
          <p className="dealProductCardProductPriceAlgolia">${productPrice}</p>
          <p className="dealProductCardDealPriceAlgolia">${dealPrice}</p>
        </div>
        <a
          className="dealProductCardLinkAlgolia"
          href={dealsPageUrl}
          target="_blank"
          rel="noreferrer sponsored"
        >
          Shop Now
        </a>
      </div>
    </div>
  ) : (
    ""
  );
};

export default DealProductHit;
