import React, { useEffect, useState } from "react";
import { DFPSlotsProvider } from "react-dfp";
import Navbar from "@herb/components/Navbar";
import { CookiesProvider } from "react-cookie";
import { ThemeProvider } from "emotion-theming";
import InstantSearch from "@herb/components/Search/InstantSearch";
import theme from "@herbkit/theme";
import AgeGate from "@herb/components/AgeGate/AgeGate";
import NewsletterOverlay from "@herb/components/NewsletterOverlay";
import NewsletterOverlayCali from "@herb/components/NewsletterOverlayCali";
import IFrame from "@herb/components/IFrame";
import GlobalSchema from "@herb/components/Schema/GlobalSchema";
import { supportsLocalStorage, useLocation } from "@herb/utils";
import Footer from "@herb/components/Footer";
import "./layout.css";

const isBrowser = typeof window !== "undefined";

function Layout({ children, hideWebPageSchema = false, page, path }) {
  const [isAgeVerified, setAgeVerified] = useState(
    supportsLocalStorage() && localStorage.getItem("ageOfMajority")
  );
  const popUpSlugs = [
    "/guides/how-to-pass-a-drug-test/",
    "/learn/pass-your-test-review/",
    "/guides/how-to-flush-marijuana-out-of-your-system/",
    "/guides/nutra-cleanse-clean-shot-review/",
    "/guides/a-guide-to-at-home-drug-tests/",
    "/guides/how-to-pass-a-drug-test",
    "/learn/pass-your-test-review",
    "/guides/how-to-flush-marijuana-out-of-your-system",
    "/guides/nutra-cleanse-clean-shot-review",
    "/guides/a-guide-to-at-home-drug-tests",
    "/news/culture/get-seeds-you-can-trust-with-expert-growing-advice-from-ilgm/",
    "/learn/get-20-white-widow-seeds-for-the-price-of-10-at-ilgm/"
  ];

  const [location, locationLoading] = useLocation();
  const [showCali, setShowCali] = useState(false);
  const [isHerbPlus, setIsHerbPlus] = useState(false);
  const [showRegular, setShowRegular] = useState(false);

  const getUtmValues = key => {
    let value = "";
    const makeQueryParam = key + "=";
    if (String(window.location.href).includes(key)) {
      const splitUrl = String(window.location.href).split(makeQueryParam)[1];
      value = splitUrl.split("&")[0];
    } else {
      value = "";
    }
    return value;
  };

  useEffect(() => {
    if (typeof window !== undefined) {
      const utmSource = getUtmValues("utm_source");
      utmSource === "1point" && localStorage.setItem("utm_source", utmSource);
    }

    if (document) {
      document.addEventListener("ageGate:verified", e => {
        setAgeVerified(true);
      });
    }
  }, []);

  // useEffect(() => {
  //   if (
  //     location &&
  //     location.region === "California" &&
  //     window.location.pathname !== "/herb-plus/" &&
  //     window.location.pathname !== "/dispensary-plus/" &&
  //     window.location.pathname !== "/herb-plus" &&
  //     window.location.pathname !== "/dispensary-plus"
  //   ) {
  //     setShowCali(true);
  //     setShowRegular(false);
  //   } else if (isBrowser) {
  //     if (
  //       window.location.pathname !== "/herb-plus/" &&
  //       window.location.pathname !== "/workshop/" &&
  //       window.location.pathname !== "/workshop/confirmation/" &&
  //       window.location.pathname !== "/dispensary-plus/" &&
  //       window.location.pathname !== "/herb-plus" &&
  //       window.location.pathname !== "/dispensary-plus"
  //     ) {
  //       setIsHerbPlus(true);
  //       setShowCali(false);
  //       setShowRegular(true);
  //     }
  //   }

  //   // eslint-disable-next-line
  // }, [location]);

  return (
    <DFPSlotsProvider
      lazyload={true}
      disableInitialLoad
      dfpNetworkId={process.env.GATSBY_DFP_NETWORK_ID}
    >
      <div
        className={`layout mainSec ${page === "deals" ? "dealsLayout" : ""}`}
      >
        <GlobalSchema hideWebPageSchema={hideWebPageSchema} />
        <IFrame />
        <CookiesProvider>
          <InstantSearch>
            <Navbar page={page} />
            <ThemeProvider theme={theme}>
              <div>{children}</div>
              {page !== "deals" && page !== "scavenger-hunt" && (
                <Footer path={path} />
              )}

              {/* {isBrowser &&
                !popUpSlugs.includes(window.location.pathname) &&
                isAgeVerified &&
                showCali &&
                isHerbPlus && <NewsletterOverlayCali />} */}
              {isBrowser &&
                !popUpSlugs.includes(window.location.pathname) &&
                isAgeVerified &&
                localStorage.getItem("utm_source") !== "1point" && (
                  <NewsletterOverlay />
                )}

              <AgeGate />
            </ThemeProvider>
          </InstantSearch>
        </CookiesProvider>
      </div>
    </DFPSlotsProvider>
  );
}

export default Layout;
