import React from "react";
import Link from "@herb/components/Link";
import { Box, Text, Flex, Icon } from "@herb/ui";
import "./index.css";
export default ({ context, path, ...props }) => {
  const { humanPageNumber, numberOfPages } = context;
  let start = Math.max(humanPageNumber - 2, 1);
  let end = start + 4;

  if (end > numberOfPages) {
    end = numberOfPages;
    start = Math.max(end - 4, 1);
  }

  return (
    <div className="pagination-wrap" {...props}>
      <div className="pagination-box prev">
        <Link
          to={`${context.previousPagePath}/`}
          className={`pagination-link ${
            context.previousPagePath ? "" : "disabled"
          }`}
        >
          <div className="pagination-icon">
            <img src={`../images/Arrows/Prev.svg`} width={24} height={24} />
          </div>
        </Link>
      </div>

      <div className="pagination-numbers">
        {Array.from({ length: end - start + 1 }, (_, i) => (
          <Link
            key={`${path}-pagination-number-${i + start}`}
            to={`${path}/${i + start === 1 ? "" : i + start}`}
            className={`pagination-number ${
              i + start === humanPageNumber ? "active" : ""
            }`}
          >
            {i + start}
          </Link>
        ))}

        {numberOfPages > 5 && end < numberOfPages && (
          <>
            <span className="pagination-ellipsis">...</span>

            <Link to={`${path}/${numberOfPages}`} className="pagination-number">
              {numberOfPages}
            </Link>
          </>
        )}
      </div>

      <div className="pagination-box next">
        <Link
          to={`${context.nextPagePath}/`}
          className={`pagination-link ${
            context.nextPagePath ? "" : "disabled"
          }`}
        >
          <div className="pagination-icon next">
            <img src={`../images/Arrows/Prev.svg`} width={24} height={24} />
          </div>
        </Link>
      </div>
    </div>
  );
};
