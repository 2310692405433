import React from "react";
import { getDate } from "@herb/utils/date";
import getCategoryPath from "@herb/utils/categoryPath";
import { deployUrl } from "@herb/utils";
import ReactHtmlParser from "react-html-parser";
import "./index.css";
import { Link } from "gatsby";

const ArticleBreadCrumb = ({
  date,
  category,
  categories,
  path,
  legacy,
  post,
  ...props
}) => {
  if (!legacy) {
    category =
      (category && (category[0] || {}).name) ||
      (category || {}).name ||
      category ||
      "Category";
  }
  const convertAlgoliaDate = d => {
    d = d.split("T");

    d = d[0].split("-");
    d = d[1] + "." + d[2] + "." + d[0];

    return d;
  };

  if ((date || "").includes("T")) {
    date = (date && convertAlgoliaDate(date)) || "01.01.2022";
  } else {
    date = (date && getDate(date)) || "01.01.2022";
  }

  return (
    <div className="articleBreadCrumb">
      <Link className="cusColor" to="/">
        Home
      </Link>
      &gt;
      <Link
        className="cusColor"
        to={path ? path : `/${getCategoryPath(categories)}`}
      >
        {category}
      </Link>
      &gt;
      <p className="articleTitle">
        {legacy ? ReactHtmlParser(post?.title) : post?.title}{" "}
        <span className="cusColor"> | </span>
      </p>
      {date}
    </div>
  );
};

export default ArticleBreadCrumb;
