import React from "react";
import Image from "@herb/components/Image";
import "./index.css";

const ProductCard = ({ deal }) => {
  const { title } = deal;
  const content = deal?.contentDeals?.content[0];
  const { product, discountCode } = content;

  return (
    <div className="product-card">
      <div className="image-container">
        <Image
          src={product?.images ? product?.images[0] : ""}
          className="product-image"
          alt="Product"
        />
      </div>

      <div className="product-details">
        <img
          src="https://herb-platform-images.imgix.net/wp-content/uploads/2025/03/18103834/dealProductBg.png"
          className="product-details-bg"
        />
        <div className="product-details-div">
          <p className="product-category">{product?.category}</p>
          <p className="brand-name">{product?.brand_name}:</p>
          <h3 className="product-title">{product?.title}</h3>
          <a
            className="shop-button"
            href={content?.dealsPageUrl}
            target="_blank"
            rel="noreferrer"
          >
            SHOP NOW
          </a>
        </div>
      </div>
    </div>
  );
};

export { ProductCard };
