import React from "react";
import Layout from "@herb/layouts";
import SEO from "@herb/components/seo";
import { deployUrl } from "@herb/utils";
import Image from "@herb/components/Image";
import "./index.css";
import { graphql } from "gatsby";
import { ProductCard } from "../../components/Cards/420DealCard";
import Slider from "react-slick";

const ThankYou = ({ data }) => {
  const {
    wordpressWpPage: { thankyouDeals }
  } = data;
  const { section1Deals, section2Deals } = thankyouDeals;

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <svg
          className="exclusiveDealsSliderArrowRight"
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="22"
          viewBox="0 0 12 22"
          fill="none"
        >
          <path d="M1 1L11 11L1 21" stroke="black" stroke-linecap="round" />
        </svg>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <svg
          className="exclusiveDealsSliderArrowLeft"
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="22"
          viewBox="0 0 12 22"
          fill="none"
        >
          <path d="M1 1L11 11L1 21" stroke="black" stroke-linecap="round" />
        </svg>
      </div>
    );
  }
  const settings = {
    dots: false,
    infinite: true,
    centerMode: false,
    arrows: true,
    speed: 500,
    centerPadding: "50px",
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      // {
      //   breakpoint: 1025,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 2
      //   }
      // },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="scavengerHuntMain">
      <Layout page="scavenger-hunt">
        <SEO
          title="The World's First Digital 4/20 Scavenger Hunt"
          description="Herb 420 Scavenger Hunt"
          url="/thank-you"
          canonical={`${deployUrl}/thank-you`}
          breadcrumbs={[]}
        />

        <div>
          <div className="thankYou-hero-section">
            <div className="thankYou-text">
              <h1 className="thankYou-title">YOU'RE IN!</h1>
              <h2 className="thankYou-subtitle">ENTRY CONFIRMED</h2>
              <p className="hero-section-description">
                Congratulations! You’ve just secured one entry into the ultimate
                4/20 giveaway.
              </p>
            </div>
          </div>
          <div className="brands-container">
            <div className="top-brands container">
              <a
                className="top-brand-item mcro"
                href="https://mcroedibles.com/?utm_source=herb&utm_medium=editorial&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/Mcro.png" />
              </a>
              <a
                className="top-brand-item inda"
                href="https://indacloud.co/?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/IndaCloud.png" />
              </a>
              <a
                className="top-brand-item viia"
                href="https://viiahemp.com/?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/VIIAHemp.png" />
              </a>
              <a
                className="top-brand-item sunmed"
                href="https://getsunmed.com/?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/sunmed.png" />
              </a>
              <a
                className="top-brand-item mitra"
                href="https://mitra-9.com/?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/mitra9.png" />
              </a>
            </div>
            <div className="middle-brands">
              <a
                className="middle-brand-item"
                href="https://stacheproducts.com/?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/stache.png" />
              </a>
              <a
                className="middle-brand-item"
                href="https://www.wildorchardhemp.com/all-products/?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/WildOrchard.png" />
              </a>
              <a
                className="middle-brand-item"
                href="https://www.lollisoda.com?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/lolli.png" />
              </a>
              <a
                className="middle-brand-item"
                href="https://galaxytreats.com?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/GalaxyTreats.png" />
              </a>
              <a
                className="middle-brand-item"
                href="https://highrisebev.com?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/highrise.png" />
              </a>
            </div>
            <div className="bottom-brands container">
              <a
                className="bottom-brand-item yumz"
                href="https://yumzlab.com/?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/Yumz.png" />
              </a>
              <a
                className="bottom-brand-item paxBottom"
                href="https://www.pax.com?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/PAX.png" />
              </a>
              <a
                className="bottom-brand-item nwtn"
                href="https://nwtnhome.com?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/NWTN.png" />
              </a>
              <a
                className="bottom-brand-item mj"
                href="https://mjarsenal.com?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/MJArsenal.png" />
              </a>
              <a
                className="bottom-brand-item modus"
                href="https://moduslife.com?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/Modus.png" />
              </a>
              <a
                className="bottom-brand-item alcapone"
                href="http://www.alcapone-us.com?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/AlCapone.png" />
              </a>
              <a
                className="bottom-brand-item binoidBottom"
                href="https://shop.binoidcbd.com?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/Binoid.png" />
              </a>
              <a
                className="bottom-brand-item dyna"
                href="https://www.dynavap.com/?utm_source=Herb&utm_medium=Social&utm_campaign=Herb"
                target="_blank"
              >
                <Image src="./images/BrandLogos/DynaVap.png" />
              </a>
            </div>
            <div className="bottom-brands2">
              <a
                className="bottom-brand-item soul"
                href="https://soul.go2cloud.org/aff_c?offer_id=1&aff_id=1014&url_id=10&utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/SOUL.png" />
              </a>
              <a
                className="bottom-brand-item bong"
                href="https://bongpong.com/?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herb&utm_id=bp1"
                target="_blank"
              >
                <Image src="./images/BrandLogos/BongPong.png" />
              </a>
              <a
                className="bottom-brand-item nail"
                href="https://e-nail.com/?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/nail.png" />
              </a>
              <a
                className="bottom-brand-item bloomz"
                href="https://bloomzhemp.com/?ref=herb&utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/Bloomz.png" />
              </a>
            </div>
          </div>
          <div className="brands-heading-container">
            <h2 className="brands-heading">Meet The Brands Hooking You Up</h2>
          </div>
          <div className="thankYouDescription container">
            <p className="thankYou-description">
              You also unlocked VIP access to the best weed deals this 4/20,
              courtesy of the brands that made this scavenger hunt possible.
              Find everything from exclusive discounts to limited drops right
              here.
            </p>
          </div>
          <div className="exclusive-deals container">
            <h2 className="exclusive-deals-heading">Exclusive Deals</h2>
            <div className="exclusive-deals-slider">
              <Slider {...settings}>
                {section1Deals?.map(deal => (
                  <ProductCard deal={deal} />
                ))}
              </Slider>
            </div>
            <div className="exclusive-deals-slider mt-70">
              <Slider {...settings}>
                {section2Deals?.map(deal => (
                  <ProductCard deal={deal} />
                ))}
              </Slider>
            </div>
          </div>
          <div className="footer-main">
            <div className="more-info container">
              <span>
                Find more stickers to lock in new entries—there's a new one
                hiding on Herb.co every single day until 4/20. Drop by tomorrow.
                Keep hunting. Stack those entries.
              </span>
            </div>
            <div className="social-links-container container mb">
              <div className="social-links">
                <a href="https://www.instagram.com/herb/" target="_blank">
                  <Image
                    src="https://herb-platform-images.imgix.net/wp-content/uploads/2025/03/25125311/insta_white.png"
                    alt="instagram"
                  />
                </a>
                <a href="https://www.threads.net/@herb" target="_blank">
                  <Image
                    src="https://herb-platform-images.imgix.net/wp-content/uploads/2025/03/25125310/thread_white.png"
                    alt="threads"
                  />
                </a>
                <a href="https://twitter.com/Herbworthy" target="_blank">
                  <Image
                    src="https://herb-platform-images.imgix.net/wp-content/uploads/2025/03/25125309/twitter_white.png"
                    alt="twitter"
                  />
                </a>
                <a href="https://www.facebook.com/herb" target="_blank">
                  <Image
                    src="https://herb-platform-images.imgix.net/wp-content/uploads/2025/03/25125313/fb_white.png"
                    alt="facebook"
                  />
                </a>
              </div>
              <p className="social-text">
                Follow our socials for exclusive clues
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default ThankYou;

export const pageQuery = graphql`
  query {
    wordpressWpPage(slug: { eq: "420-giveaway" }) {
      id
      title
      thankyouDeals {
        section1Deals {
          ... on WordpressWpDeal {
            title
            featuredImage {
              node {
                altText
                sourceUrl
              }
            }
            contentMobileImage {
              mobileImage {
                altText
                sourceUrl
              }
            }
            contentDeals {
              content {
                ... on WordpressWpDeal_Contentdeals_Content_ProductDeal {
                  dealPrice
                  dealsPageUrl
                  productPrice
                  badgeText
                  badgeTextColor
                  discountCode
                  product {
                    ... on WordpressWpProduct {
                      id
                      brand_name
                      images
                      title
                    }
                  }
                }
              }
            }
          }
        }
        section2Deals {
          ... on WordpressWpDeal {
            title
            featuredImage {
              node {
                altText
                sourceUrl
              }
            }
            contentMobileImage {
              mobileImage {
                altText
                sourceUrl
              }
            }
            contentDeals {
              content {
                ... on WordpressWpDeal_Contentdeals_Content_ProductDeal {
                  dealPrice
                  dealsPageUrl
                  productPrice
                  badgeText
                  badgeTextColor
                  discountCode
                  product {
                    ... on WordpressWpProduct {
                      id
                      brand_name
                      images
                      title
                      category
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
