import React from "react";
import Image from "@herb/components/Image";
import "./index.css";
import { fallbackImages } from "../../../utils/constants";

const DispensaryDealCard = ({ deal, cardNum }) => {
  const { dealsPageUrl } = deal.contentDeals.content[0];
  const { contentMobileImage, featuredImage } = deal;

  return (
    <div className="dispensaryDealCard">
      <a
        className="dispensaryDealCardImageLink desktop"
        href={dealsPageUrl}
        target="_blank"
        rel="noreferrer sponsored"
      >
        <Image
          className="dispensaryDealCardImage"
          src={featuredImage?.node?.sourceUrl}
          width={1300}
        />
      </a>
      <a
        className="dispensaryDealCardImageLink mobile"
        href={dealsPageUrl}
        target="_blank"
        rel="noreferrer sponsored"
      >
        <>
          <Image
            className="dispensaryDealCardImage"
            src={contentMobileImage?.mobileImage?.sourceUrl}
            width={500}
          />
        </>
      </a>
    </div>
  );
};

export default DispensaryDealCard;
