import React from "react";
import Image from "@herb/components/Image";
import "./index.css";

const PrizePoolCard = ({ prize }) => {
  return (
    <div className="prizeCardMain">
      <div className="prizeImageContainer">
        <Image
          src={prize?.sourceUrl}
          alt="420 Price"
          className="prizeCardImage"
        />
      </div>
    </div>
  );
};

export { PrizePoolCard };
