import React from "react";
import { track } from "@herb/utils/analytics";
import "./index.css";
import productLink from "@herb/utils/productLink";
import Button from "@herb/components/Button";
import Image from "@herb/components/Image";
import { Link } from "gatsby";
import { fallbackImages } from "../../../utils/constants";

const SliderProductCard = ({ productdetails, section }) => {
  const {
    acf,

    contentProducts
  } = productdetails;

  const image = acf ? acf.product_images[0] : contentProducts.productImages[0];
  const sourceUrl = image.sourceUrl || image.source_url;
  const altText = (image || {}).altText || (image || {}).alt_text;
  const { affiliateUrl } = contentProducts;
  const category = productdetails?.productCategory
    ? productdetails?.productCategory
    : contentProducts?.productCategory;

  const trackShopButtonEvent = to => () => {
    let analytics = { locationOnPage: "Product Page Affiliate CTA" };

    if (typeof window !== undefined) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "Shop Button Clicked"
      });
    }

    track("Shop Button Click", {
      to,
      ...(analytics || {})
    });

    return true;
  };

  return (
    <div className="sliderProductMinDiv">
      <Link
        analytics={trackShopButtonEvent(affiliateUrl)}
        to={`${productLink(productdetails)}`}
        style={{ color: "inherit" }}
      >
        <div className="sliderProductImage">
          <Image
            sizes="(min-width: 40em) 30vw, 40vw"
            src={sourceUrl || image}
            alt={altText || "product"}
            style={{
              maxHeight: "240px"
            }}
            css={{ objectFit: "contain" }}
            imgixParams={{ ar: "3:4", fit: "clip" }}
            height={240}
            width={212}
          />
        </div>
      </Link>
      <div className={"sliderProductCategory"}>
        <Link className="mCategory" to={`/products/${(category || {}).slug}/`}>
          {(category || {}).name}
        </Link>
      </div>
      <Link
        analytics={trackShopButtonEvent(affiliateUrl)}
        to={`${productLink(productdetails)}`}
        style={{ color: "inherit" }}
      >
        <div
          className={
            section === "middleSection"
              ? "middleSectionSliderProductTitle"
              : "sliderProductTitle"
          }
        >
          {productdetails.title}
        </div>
      </Link>
      <div className="sliderProductShopButton">
        <Button
          // href={affiliateUrl}
          href={`${productLink(productdetails)}`}
          // analytics={trackShopButtonEvent(
          //   productdetails.contentProducts.affiliateUrl
          // )}
          target="_self"
          link
          stlyes={{
            fontStyle: "italic",
            padding: "6px 18px",
            fontSize: "20px",
            lineHeight: "24px",
            display: "inline-block",
            color: " #141313"
          }}
          type={"boutiqueButton"}
          // rel="noopener noreferrer"
        >
          View Product
        </Button>
      </div>
      {/* <div className="sliderProductViewButton">
        <Link
          href={`${productLink(productdetails)}`}
          className={
            section === "middleSection"
              ? "middleSectionSliderViewButton"
              : "sliderViewButton"
          }
        >
          View Product
        </Link>
      </div> */}
    </div>
  );
};

export default SliderProductCard;
